import React from 'react'
import './WhyLB.css'


export default function WhyLB() {
    return (
        <div className="whyLb" id="whyLb" >
            {/* <h1>  ؟ LB لماذا  </h1> */}
            <div className="top">
                <h1 dir="ltr">  Why LB ?  </h1>
                <p>نقوم بتحويل تعلم اللغة إلى تسلية
                    نقوم بتحويل تعلم اللغة إلى تسلية
                </p>
            </div>
            <div className="container">
                <div className="card">
                    <h2> Lorem ipsum</h2>
                    <div className="top">
                    </div>
                    <div className="center">
                        لوريم ايبسوم لوريم ايبسوم
                    </div>
                    <div className="bottom">
                        <h3>H3 3H3 H3 H3 3H</h3>
                        <h4>لوريم ايبسوم لوريم ايبسوم</h4>
                        <h4>لوريم ايبسوم لوريم ايبسوم</h4>

                    </div>
                </div>
                <div className="card second">
                    <img src="assets/Nerd-pana.svg" alt="" />

                </div>
                <div className="card">
                    <h2>Lorem ipsum</h2>
                    <div className="top">
                    </div>
                    <div className="center">
                        لوريم ايبسوم لوريم ايبسوم
                    </div>
                    <div className="bottom">
                        <h3>H3 3H3 H3 H3 3H</h3>
                        <h4>لوريم ايبسوم لوريم ايبسوم</h4>
                        <h4>لوريم ايبسوم لوريم ايبسوم</h4>

                    </div>
                </div>
            </div>




        </div>
    )
}
